import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { CALENDAR_EVENTS_URL, EVENTS_URL } from "../../models/const/api";
import { CalendarEventsApiDataModel, EventModel, EventsApiDataModel } from '../../models/interfaces/events-model';
import { EVENTS_INITIAL_DATE_FROM, EVENTS_INITIAL_DATE_TO } from '../../../utils/consts/const-dates';
import { prepareDateApiParameter, prepareDateToApiParameter } from '../../../utils/consts/const-methods';

@Injectable({
    providedIn: 'root'
})
export class EventsService {

    private readonly eventsDateFromListBoundaryBS: BehaviorSubject<Date> = new BehaviorSubject<Date>(EVENTS_INITIAL_DATE_FROM);

    private readonly eventsDateToListBoundaryBS: BehaviorSubject<Date> = new BehaviorSubject<Date>(EVENTS_INITIAL_DATE_TO);

    private readonly isCalendarLoadingBS: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    readonly isCalendarLoading$: Observable<boolean> = this.isCalendarLoadingBS.asObservable();

    private readonly isSidebarCalendarLoadingBS: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    readonly isSidebarCalendarLoading$: Observable<boolean> = this.isSidebarCalendarLoadingBS.asObservable();

    private readonly isEventsLoadingBS: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    readonly isEventsLoading$: Observable<boolean> = this.isEventsLoadingBS.asObservable();

    set isCalendarLoading(isLoading: boolean) {
        this.isCalendarLoadingBS.next(isLoading);
    }

    set isSidebarCalendarLoading(isLoading: boolean) {
        this.isSidebarCalendarLoadingBS.next(isLoading);
    }

    set isEventsLoading(isLoading: boolean) {
        this.isEventsLoadingBS.next(isLoading);
    }

    set eventsDateFromListBoundary(date: Date) {
        this.eventsDateFromListBoundaryBS.next(date);
    }

    get eventsDateFromListBoundary(): Date {
        return this.eventsDateFromListBoundaryBS.getValue();
    }

    set eventsDateToListBoundary(date: Date) {
        this.eventsDateToListBoundaryBS.next(date);
    }

    get eventsDateToListBoundary(): Date {
        return this.eventsDateToListBoundaryBS.getValue();
    }

    constructor(
        private readonly httpClient: HttpClient,
    ) { }

    getEvents(dateFrom: Date, dateTo?: Date, page?: number): Observable<EventsApiDataModel> {
        const params: any = {
            dateFrom: prepareDateApiParameter(dateFrom),
            ...(dateTo && { dateTo: prepareDateToApiParameter(dateTo) }),
            ...(page !== undefined && { page })
        };

        return this.httpClient.get<EventsApiDataModel>(EVENTS_URL, {
            withCredentials: true,
            params
        });
    }

    getCalendarEvents(dateFrom: Date, dateTo: Date): Observable<CalendarEventsApiDataModel> {
        return this.httpClient.get<CalendarEventsApiDataModel>(CALENDAR_EVENTS_URL, {
            withCredentials: true,
            params: {
                dateFrom: prepareDateApiParameter(dateFrom),
                dateTo: prepareDateToApiParameter(dateTo)
            }
        });
    }

    getSpecificEvent(slug: string): Observable<EventModel> {
        return this.httpClient.get<EventModel>(EVENTS_URL + '/' + slug, {
            withCredentials: true,
        })
    }
}
